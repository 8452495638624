<template>
  <div>
    <HorizontalPreview
      v-if="previewType==='horizontal'"
      :title="title"
      :main-asset-url="mainAssetUrl"
      :sponsored="sponsored"
      @handleClick="onClick"
    />
    <VerticalPreview
      v-if="previewType==='vertical'"
      :title="title"
      :description="description"
      :main-asset-url="mainAssetUrl"
      :icon-asset-url="iconAssetUrl"
      :sponsored="sponsored"
      :cta="cta"
      @handleClick="onClick"
    />
  </div>
</template>

<script>
import HorizontalPreview from "@/components/Infeed/HorizontalPreview";
import VerticalPreview from "@/components/Infeed/VerticalPreview";

export default {
  components: {VerticalPreview, HorizontalPreview},
  props: {
    mainAssetUrl: {
      type: String,
      default: 'src/resources/images/noImage1200x628.png',
      required: false,
    },
    iconAssetUrl: {
      type: String,
      default: 'src/resources/images/noImage300x300.png',
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    cta: {
      type: String,
      required: false,
    },
    sponsored: {
      type: String,
      required: false,
    },
    lpUrl: {
      type: String,
      required: false,
    },
    previewType: {
      type: String,
      required: false,
    },
  },
  methods: {
    onClick() {
      this.$emit('handleClick')
    },
  },
}
</script>

<style scoped>
</style>
