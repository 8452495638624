<template>
  <div class="custom-card" @click="onClick">
    <div class="card-content">
      <div class="card-text">
        <div class="title">{{ title }}</div>
        <div class="sponsored">
          <div class="sponsored-text">広告・{{ sponsored }}</div>
        </div>
      </div>
      <div class="card-image">
        <img
          :src="mainAssetUrl"
          class="image"
          @contextmenu.prevent
          @dragstart.prevent
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HorizontalPreview",
  props: {
    title: {
      type: String,
      required: false,
    },
    mainAssetUrl: {
      type: String,
      required: false,
    },
    sponsored: {
      type: String,
      required: false,
    },
  },
  methods: {
    onClick() {
      this.$emit('handleClick')
    },
  },
};
</script>

<style scoped>
.custom-card {
  display: flex;
  align-items: center;
  height: 64px;
  width: 320px;
  border: 1px solid #cccccc;
  box-shadow: none;
  cursor: pointer;
  overflow: hidden;
}

.card-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.card-text {
  padding: 0.5rem 1rem;
  overflow: hidden;
}

.title {
  font-size: 0.8rem;
  font-weight: bold;
  line-height: 1.2;
  height: 2.4em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.sponsored {
  padding-top: 2px;
  display: flex;
  align-items: center;
}

.sponsored-text {
  font-size: 0.7rem;
  color: dimgray;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.card-image {
  height: 64px;
  width: 120px;
  background-color: #eeeeee;
  flex-shrink: 0;
}

.image {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
  user-select: none;
}
</style>
