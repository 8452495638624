<template>
  <div class="custom-card" @click="onClick">
    <div class="card-image">
      <img
        :src="mainAssetUrl"
        class="main-image"
        draggable="false"
        @contextmenu.prevent
        @dragstart.prevent
      />
    </div>
    <div class="card-content">
      <div class="icon-container">
        <img
          :src="iconAssetUrl"
          class="icon-image"
          draggable="false"
          @contextmenu.prevent
          @dragstart.prevent
        />
      </div>
      <div class="text-container">
        <div class="combined-text"><strong>{{ title }}</strong>&nbsp;{{ description }}</div>
      </div>
    </div>
    <div class="card-footer">
      <div class="sponsored">広告・{{ sponsored }}</div>
      <button class="cta-button" @click.stop="onClick">{{ cta }} ＞</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "VerticalPreview",
  props: {
    title: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    mainAssetUrl: {
      type: String,
      required: false,
    },
    iconAssetUrl: {
      type: String,
      required: false,
    },
    sponsored: {
      type: String,
      required: false,
    },
    cta: {
      type: String,
    },
  },
  methods: {
    onClick() {
      this.$emit('handleClick');
    },
  },
};
</script>

<style scoped>
.custom-card {
  width: 300px;
  border: 1px solid #cccccc;
  box-shadow: none;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.card-image {
  width: 100%;
  height: 157px;
  background-color: #eeeeee;
}

.main-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.card-content {
  display: flex;
  align-items: flex-start;
  padding: 0.5rem 1rem;
}

.icon-container {
  margin-right: 8px;
}

.icon-image {
  width: 50px;
  height: 50px;
  object-fit: contain;
  object-position: center;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.text-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
}

.combined-text {
  font-size: 0.9rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 100%; /* Ensure the text container takes full height */
}

.card-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.5rem;
}

.sponsored {
  font-size: 0.8rem;
  color: dimgray;
  margin-bottom: 4px;
  text-align: left;
  align-self: flex-start;
}

.cta-button {
  font-size: 0.8rem;
  font-weight: bold;
  background: none;
  border: none;
  color: #007BFF;
  cursor: pointer;
  padding: 0;
  text-decoration: none;
}
</style>
