<template>
  <div id="app">
    <InfeedPreview
      :title="title ? title : '見出し'"
      :description="description ? description : '説明文'"
      :main-asset-url="mainAssetUrl ? mainAssetUrl : require('@/resources/images/noImage1200x628.png')"
      :icon-asset-url="iconAssetUrl ? iconAssetUrl : require('@/resources/images/noImage300x300.png')"
      :sponsored="sponsored ? sponsored : '広告主体者名'"
      :cta="cta ? cta : '詳しくはこちら'"
      :preview-type="previewType ? previewType : 'vertical'"
      @handleClick="onClick"
    />
  </div>
</template>

<script>
import InfeedPreview from "@/components/Infeed/InfeedPreview";

export default {
  name: 'App',
  components: { InfeedPreview },
  data() {
    return {
      // いずれ他の形式にも対応するかも
      type: 'infeed',
      title: null,
      description: null,
      mainAssetUrl: null,
      iconAssetUrl: null,
      sponsored: null,
      lpUrl: null,
      cta: null,
      previewType: null,
    }
  },
  methods: {
    onClick() {
      if (this.lpUrl === null) return;
      window.open(this.lpUrl, "_blank", "noopener,noreferrer")
    }
  },
  created() {
    const params = location.search
    const urlSearchParams = new URLSearchParams(params)

    this.title = urlSearchParams.get('title')
    this.description = urlSearchParams.get('description')
    this.mainAssetUrl = urlSearchParams.get('mainAssetUrl')
    this.iconAssetUrl = urlSearchParams.get('iconAssetUrl')
    this.sponsored = urlSearchParams.get('sponsored')
    this.lpUrl = urlSearchParams.get('lpUrl')
    this.cta = urlSearchParams.get('cta')
    this.previewType = urlSearchParams.get('previewType')
  },
}
</script>

<style>
#app {
  width: 100%;
}
</style>
